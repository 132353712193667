<template>
  <div class="md-live-pages-container">
    <div class="live-box" v-if="liveVideo.length > 0">
      <img :src="`/video-dev${liveVideo[0].cover}`" alt="" />
      <div>
        <ul>
          <li
            v-for="(item, index) in liveVideo"
            :key="index"
            @click="goVideo(item)"
          >
            <span class="icon-circle"></span>
            <p class="c-time">
              {{ rendertime(item.liveStartTime, item.liveEndTime) }}
            </p>
            <div class="c-type-box">
              <p class="c-tit">{{ item.name }}</p>
            </div>
            <p class="c-title" v-html="rendertitle(item.liveStartTime)"></p>
          </li>
        </ul>
      </div>
    </div>
    <div class="course-box">
      <div class="tab-box">
        <ul class="tab-block">
          <li
            :class="[
              'tab-item',
              tabIndex === item.rid ? 'tab-item-active' : '',
            ]"
            v-for="(item, index) in dictList"
            :key="index"
            @click="handleTab(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="md-class-container">
        <ul class="md-class-list" v-if="liveList.length">
          <li v-for="(item, index) in liveList" :key="index">
            <LiveItemClassComponent :classInfo="item" />
            <!-- <div class="md-item-class-component" @click="turnVideo(item)">
              <img :src="`/video-dev${item.cover}`" alt="">
              <p class="title ellipsis2">{{ item.name }}</p>
              <p class="one">{{ item.courseDescription }}</p>
              <p class="one" v-if="item.liveStartTime">直播开始时间：{{ item.liveStartTime }}</p>
              <p class="one" v-if="item.liveStartTime">直播结束时间：{{ item.liveEndTime }}</p>
            </div> -->
          </li>
        </ul>
        <p v-else class="no-data">暂无数据</p>
        <!-- 分页 -->
        <div class="pagination" v-if="total > 8">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="+params.pageNum"
            :page-size="params.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectLiveList, selectLiveTypeList } from "@/axios/live";
import { getBannerList, getDictType } from "@/axios/home";
import LiveItemClassComponent from "../components/class/liveItem/index.vue";
export default {
  name: "mdLivePagesComponent",
  components: {
    LiveItemClassComponent,
  },
  data() {
    return {
      liveIndex: 0,
      liveDates: [],
      liveList: [],
      liveVideo: [],
      params: {
        classId: 1,
        pageNum: 1,
        pageSize: 12,
      },
      total: 0,
      tabIndex: 0,
      dictList: [],
      liveInfo: {},
      swiperList: [],
    };
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem("isLogin");
    },
    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
  },
  created() {

    this.getDictType();
    this.selectLiveTypeListData();
    this.getBannerList();
    this.getLiveList();
  },
  methods: {
    async getDictType() {
      const res = await getDictType("md_live_type");

      if (res.code === 200) {
        this.dictList = res.data.reverse();
        this.tabIndex = this.dictList[0].classId;
      
        this.selectLiveListdata();
      }
    },
    async getLiveList() {
      const res = await selectLiveList({
        pageNum: 1,
        pageSize: 5,
      });
      if (res.code == 200) {
        this.liveVideo = res.rows;
      }
    },
    rendertitle(liveStartTime) {
      let nowtimeS = new Date().getTime();
      let startTime = new Date(liveStartTime).getTime();
      // let endtime = new Date(liveEndTime).getTime()

      if (nowtimeS < startTime) {
        return `<span class="c-title">预约直播</span>`;
      } else {
        return `<span>马上看</span>`;
      }
    },
    async selectLiveTypeListData() {
      const res = await selectLiveTypeList();
      if (res.code == 200) {
        this.dictList = res.rows;
        this.tabIndex = this.dictList[0].rid;
        this.params.classId = this.dictList[0].rid + "";
      }
    },
    async selectLiveListdata() {
      const res = await selectLiveList(this.params);
      if (res.code === 200) {
        this.liveList = res.rows;
        this.liveDates = this.liveList
          .filter((item) => {
            return item.isReferral === "1";
          })
          .slice(0, 3);
        this.liveDates = this.liveDates.map((item) => {
          return {
            ...item,
            m: new Date(item.liveStartTime).getMonth() + 1,
            d: new Date(item.liveStartTime).getDate(),
          };
        });
        this.liveInfo = this.liveDates[0];
        this.total = res.total;
      }
    },
    async getBannerList() {
      // 获取banner
      const res = await getBannerList({
        type: 2,
      });
      if (res.code === 200) {
        this.swiperList = res.data.slice(1);
      }
    },
    handleTab(item) {
      this.tabIndex = item.rid;
      this.params.classId = item.rid;
      this.params.pageNum = 1;
      this.selectLiveListdata();
    },
    handleDateChange(l, i) {
      this.liveIndex = i;
      this.liveInfo = l;
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.selectLiveListdata();
    },
    rendertime(startTime, endTime) {
      // 将开始时间和结束时间转换为Date对象
      let start = new Date(startTime);
      let end = new Date(endTime);
      // 获取月份、日期和小时
      let month = start.getMonth() + 1;
      let day = start.getDate();
      let startHour = start.getHours();
      let endHour;
      let lastendHour;
      endHour = end.getHours();
      // 格式化月份和日期
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (endHour == 0) {
        lastendHour = endHour + "0";
      } else {
        lastendHour = endHour;
      }
      // 返回格式化后的时间段
      return (
        month + "月" + day + "日 " + startHour + ":00 - " + lastendHour + ":00"
      );
    },

    goVideo(item) {
      if (this.isLogin) {
        if (item.video !== "") {
          let routeData = this.$router.resolve({
            path: "/livevideo",
            query: {
              vUrl: "https://www.coollearn.cn/video-dev/" + item.video,
            },
          });
          window.open(routeData.href, "_blank");
        } else {
          this.$message.error("暂无视频，敬请期待");
        }
      } else {
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.md-live-pages-container {
  width: 100%;
  margin-top: 20px;

  .live-box {
    width: 1185px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 12px;

    > :nth-child(1) {
      width: 465px;
      height: 310px;
      border-radius: 12px;
    }

    > :nth-child(2) {
      width: 747px;
      height: 310px;
    }

    ul {
      // padding: 5px;
      box-sizing: border-box;

      li {
        display: flex;
        padding: 5px 20px;
        line-height: 20px;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;
        cursor: pointer;

        .c-time {
          font-size: 14px;
          color: #808080;
          margin-left: 6px;
        }

        .c-title {
          display: inline-block;
          width: 88px;
          line-height: 32px;
          border-radius: 16px;
          color: #fff;
          background-color: #ff8810;
          text-align: center;
        }

        span {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ff8810;
        }

        .c-tit {
          width: 394px;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
        }

        .c-type-box {
          margin-left: 21px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .course-box {
    width: 100%;
    margin-top: 60px;

    .tab-box {
      width: 100%;
      height: 68px;

      .tab-block {
        width: 1152px;
        height: 68px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .tab-item {
          position: relative;
          float: left;
          margin-right: 40px;
          font-size: 16px;
          color: #333;
          line-height: 40px;
          cursor: pointer;
          font-weight: 700;

          &::before {
            content: " ";
            position: absolute;
            width: 40px;
            height: 3px;
            background: transparent;
            bottom: -4px;
            margin-left: -10px;
            left: 39%;
            border-radius: 2px;
          }
        }

        .tab-item-active {
          font-size: 24px;
          color: @md-primary-color;

          &::before {
            background: @md-primary-color;
          }
        }
      }
    }

    .md-class-container {
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0;

      .md-class-list {
        display: flex;
        flex-wrap: wrap;

        li {
          &:nth-child(4n) {
            .md-item-class-component {
              margin-right: 0;
            }
          }

          .md-item-class-component {
            position: relative;
            width: 285px;
            height: 308px;
            float: left;
            margin: 0 20px 20px 0;
            box-shadow: 0 4px 8px 0 rgba(95, 101, 105, 0.05);
            border-radius: 8px;
            background-color: #fff;
            transition: transform 0.2s, box-shadow 0.2s;
            cursor: pointer;

            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 12px 20px 0 rgba(95, 101, 105, 0.1);
            }

            img {
              width: 100%;
              height: 152px;
              background: no-repeat center/cover;
              margin-bottom: 8px;
              border-radius: 8px 8px 0 0;
              overflow: hidden;
            }

            .title {
              width: 100%;
              color: #545c63;
              line-height: 20px;
              height: 40px;
              margin-top: 8px;
              padding: 0 8px;

              &:hover {
                color: @md-primary-color;
              }
            }

            .ellipsis2 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .one,
            .two {
              font-size: 12px;
              color: #9199a1;
              line-height: 18px;
              padding: 0 8px;
              margin-bottom: 8px;
              height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.scroll-bar {
  &::-webkit-scrollbar-track {
    // border-radius: 10px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #efefef;
  }

  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // background-color: rgba(0, 0, 0, 0.2);
    background-color: #888;
  }
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

/deep/ .el-pagination .btn-next,
.el-pagination .btn-prev {
  // background: transparent !important;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

/deep/ .el-pagination button:disabled {
  // background: transparent !important;
}

/deep/ .el-pager li {
  // background: transparent !important;
}

/deep/ .el-pager li.active {
  color: @md-primary-color;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0fb09b;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    border: 1px solid #cccccc;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #0fb09b;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 36px;
    line-height: 36px;
  }
}

.md-swiper-box {
  flex: 1;
}

/deep/ .el-carousel__container .el-carousel__item .swiper-img {
  width: 100%;
}

/deep/ .el-carousel__item.is_animating {
  transition: 1s all;
}

/deep/ .el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
